.search {
    display: grid;
    grid-template-columns: 140px 1fr;
    /* background: rgb(108,213,255);
    background: linear-gradient(0deg, rgba(108,213,255,1) 0%, rgba(255,237,166,1) 100%);  */
    animation: fade-in 2.5s;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }
