.sign {
    /* background-image: url("./record-image.jpeg");
    background-size: 100%; */
    animation: fade-in 2.5s;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }