.statistics {
    display: grid;
    grid-template-columns: 140px 1fr;
    /* background: rgb(108,213,255);
    background: linear-gradient(0deg, rgba(108,213,255,1) 0%, rgba(255,237,166,1) 100%); */
    animation: fade-in 2.5s;
}

.example-style {
  animation: fade-in 0.5s;
}

.tab {
  -webkit-transition: margin 0.5s ease;
  
  transition: margin 0.5s ease;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
